<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="标签名称" >
                <el-input v-model="search.biaoQianMC" size="small" />
            </x-search-item>
            <x-search-item label="标签类型" >
                <el-input v-model="search.biaoQianLX" size="small" />
            </x-search-item>
            <x-search-item label="标签描述" >
                <el-input v-model="search.biaoQianMS" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">
                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>
            </el-popconfirm>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                @selection-change="handleSelectionChange">
            <el-table-column type="selection" width="40" fixed/>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="150" label="标签预览">
                <x-Hy-tag slot-scope="{row}" :opts="row"></x-Hy-tag>
            </el-table-column>
            <el-table-column  prop="biaoQianMC" label="标签名称" />
            <el-table-column  prop="biaoQianLX" label="标签类型" >
                <x-dict-show slot-scope="{row}" :code="row.biaoQianLX" dictType="T_HY_TAG" />
            </el-table-column>
            <el-table-column  prop="biaoQianMS" label="标签描述" />
            <el-table-column  prop="shiYongSL" label="使用数量"/>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
                    <el-button type="primary" size="mini" round @click="handleUpdate(row.id)">编辑</el-button>
                    <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/hygl/HuiYuanBiaoQian";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/hygl/hybq/HuiYuanBiaoQianEdit";
    import Detail from "@/view/hygl/hybq/HuiYuanBiaoQianDetail";
    import XHyTag from "@/view/hygl/hybq/XHyTag";

    export default {
        name: "HuiYuanBiaoQianList",
        mixins: [XTableBase],
        components: {Edit, Detail, XHyTag},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                search: {
                    biaoQianMC: '',
                    biaoQianLX: '',
                    biaoQianMS: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>
