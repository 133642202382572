<template>
    <el-dialog title="详情" :visible.sync="detail.visible" @open="open"
        destroy-on-close center append-to-body width="1000px">
        <x-detail label-width="140px" :entity="entity">
            <x-detail-item prop="biaoQianMC" label="标签名称" />
            <x-detail-item prop="biaoQianLX" label="标签类型" >
                <x-dict-show :code="entity.biaoQianLX" dictType="T_HY_TAG"/>
            </x-detail-item>
            <x-detail-item prop="biaoQianMS" label="标签描述" />
        </x-detail>
    </el-dialog>
</template>

<script>
    import {getDetail} from "@/service/hygl/HuiYuanBiaoQian";
    import XDetailBase from "@/components/x/XDetailBase";

    export default {
        mixins: [XDetailBase],
        beforeCreate() {
            this.defaultModel = {
                biaoQianMC: "", // 标签名称
                biaoQianLX: "", // 标签类型
                biaoQianMS: "", // 标签描述
            };
            this.getDetailService = getDetail;
        }
    }
</script>

<style scoped>

</style>