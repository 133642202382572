<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open"
            :close-on-click-modal="false" destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="标签名称" prop="biaoQianMC" >
                <el-input v-model="form.biaoQianMC" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
            <el-form-item label="标签类型" prop="biaoQianLX" >
                <x-selector-one v-model="form.biaoQianLX" dictType="T_HY_TAG"/>
            </el-form-item>
            <el-form-item label="标签预览">
                <x-hy-tag :opts="form"></x-hy-tag>
            </el-form-item>
            <el-form-item label="标签描述" prop="biaoQianMS" >
                <el-input v-model="form.biaoQianMS" size="small" maxlength="36" show-word-limit/>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, update, getUpdate} from "@/service/hygl/HuiYuanBiaoQian";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import XHyTag from "@/view/hygl/hybq/XHyTag";

    export default {
        mixins: [XEditBase],
        components: {XHyTag},
        beforeCreate() {
            let {required} = ruleBuilder;
            this.rules = {
                biaoQianMC: [required()], // 标签名称
                biaoQianLX: [required()], // 标签类型
                biaoQianMS: [], // 标签描述
            }
            this.titlePrefix = '会员标签';
            this.defaultForm = {
                id: null,
                biaoQianMC: "", // 标签名称
                biaoQianLX: "", // 标签类型
                biaoQianMS: "", // 标签描述
            };
            this.addService = add;
            this.updateService = update;
            this.getUpdateService = getUpdate;
        }
    }
</script>

<style scoped>

</style>